
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800&display=swap');

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Thin.otf');
    font-weight: 200;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Light.otf');
    font-weight: 300;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Regular.otf');
    font-weight: 400;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-RegularItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Medium.otf');
    font-weight: 500;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Semibold.otf');
    font-weight: 600;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Bold.otf');
    font-weight: 700;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

:root {
    color-scheme: light dark;
  }

* {
    font-family: 'SF-Pro-Display', sans-serif;
  }

body{
    margin: 0px;
    background-color: light-dark(white, black);
}

html, body {
	position:relative;
	overflow-x:hidden;
}


div{
    white-space: pre-line;
}


h1{
    font-weight: 700;
    font-size: 30pt;
    color: hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 100px;
}


h2{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 700;
    font-size: 15pt;
    color:hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 40px;
    margin-bottom: 0px;
}


h3{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 700;
    font-size: 13pt;
    color:hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 20px;
    margin-bottom: 0px;
}

p{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 300;
    font-size: 15pt;
    color: light-dark(hsl(0, 0%, 20%), hsl(0, 0%, 80%));
    text-align: justify;
    line-height: 150%;
}


a:link, a:visited {
    color: hsl(0, 0%, 25%);
    fill: hsl(0, 0%, 25%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 75%);
        fill: hsl(0, 0%, 75%);
    }
    text-decoration: none;
  }

  
a:hover, a:active {
    opacity: 50%;
}

.socialMediaLink {
    margin: 10px;
}



.appName{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 44pt;
    @media (prefers-color-scheme: light) {
        color: black;
    };
    @media (prefers-color-scheme: dark) {
        color: white;
    };
}

.appNameSmall{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 18pt;
    color: light-dark(black, white);
}


.slogan{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 300;
    font-size: 20pt;
    color: light-dark(black, white);
}


.featureTitle{
    font-weight: 700;
    font-size: 50pt;
    color: light-dark(black, white);
    text-align: center;
}

.featureTitleBackground{
    font-weight: 700;
    font-size: 50pt;
    filter: blur(20px);
    z-index: -1;
}

.featureSubtitle{
    font-weight: 700;
    font-size: 30pt;
    text-align: center;
}



.textGradientOrange{
    background: linear-gradient(28deg, hsl(30, 100%, 50%), hsl(38, 100%, 50%), hsl(45, 100%, 50%));
    background-clip: text;
    color: transparent;
}

.textGradientBlue{
    background: linear-gradient(30deg, hsl(220, 100%, 60%),hsl(210, 100%, 50%),hsl(190, 100%, 50%));
    background-clip: text;
    color: transparent;
}

.textGradientYellow{
    background: linear-gradient(30deg, hsl(38, 100%, 60%),hsl(48, 100%, 50%),hsl(57, 100%, 50%));
    background-clip: text;
    color: transparent;
}

.textGradientPurple{
    background: linear-gradient(30deg, hsl(190, 100%, 50%), hsl(230, 100%, 60%),hsl(270, 100%, 60%),hsl(300, 100%, 50%));
    background-clip: text;
    color: transparent;
}

.textGradientLights{
    background: linear-gradient(30deg, hsl(50, 100%, 50%),  hsl(50, 100%, 50%), hsl(30, 100%, 50%), hsl(300, 100%, 50%), hsl(200, 100%, 50%) ,hsl(190, 100%, 60%));
    background-clip: text;
    color: transparent;
}


.featureDescription{
    font-weight: 500;
    font-size: 18pt;
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
    box-sizing: border-box;
}

.featureQuote{
    font-weight: 200;
    font-size: 30pt;
    color: white;
    padding: 5%;
    box-sizing: border-box;
    text-align: center;
}

.featureQuoteAuthor{
    font-weight: 400;
    font-size: 20pt;
    font-style: italic;
    color: white;
    width: 100%;
    padding-left: 50%;
    padding-right: 15%;
    box-sizing: border-box;
    text-align: right;
}

.featureMedia{
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureImage{
    width: 100%;
    border-radius: 30px;

}

.featureImageBackground{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 30px;
    filter: blur(30px);
    z-index: -1;
}

.width5{
    width: 5%;
}

.width10{
    width: 10%;
}

.width45{
    width: 45%;
}

.width50{
    width: 50%;
}

.padding2{
    padding: 2%;
}

.maxWidth500px{
    max-width: 500px;
}


.minorFeature{
    width: 250px;
    margin: 10px;
    padding: 0px 20px;
    background-color: white;
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 5%);
    }
    border-radius: 12px;
    box-shadow: 0px 6px 6px hsla(0, 0%, 0%, 0.1);
}

.homepage {
    display: block;
}

.strip {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.stripContent{
    display: block;
    width: 90%;
    max-width: 1200px;

    @media (max-width: 800px){
        max-width: 500px;
    }
}


.infoPage .stripContent{
    display: block;
    width: 90%;
    max-width: 800px;
}

.fullHeight{
    min-height: 100vh;
}

.logoStrip{
    background:
            radial-gradient(ellipse at top, light-dark(#ebebeb, #000000), transparent, transparent),
            radial-gradient(ellipse at center, light-dark(#ffffff, #121212), transparent, transparent);
}



.logoBackground{
    width: 140vw;
    height: 140vw;
    filter: blur(80px) saturate(2);
    z-index: -2;
    margin: -80vw auto;
    opacity: 0.5;
}

.logoBackgroundCircle{
    height: 600px;
    width: 600px;
    border-radius: 50%;
    filter: blur(50px);
    background-color: light-dark(white, rgba(77, 0, 255, 0.1));
    z-index: -1;
    margin: -200px auto;
}

.footerStrip{
    background-color: hsl(0, 0%, 95%);
    border-top: 1px solid hsl(0, 0%, 85%);
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 5%);
        border-top: 1px solid hsl(0, 0%, 15%);
    }
}

.headerBar {
    background-color:white;
    box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.12);
    @media (prefers-color-scheme: dark) {
        background-color:black;
        box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.12);
    }
    position: sticky;
    top: 0;
    width: 100%;
  }

.hStack{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.vStack{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.zStack{
    display: grid;
  > * {
    grid-area: 1 / 1;
  }
}

.zStack-center{
    
}

.vStack-left{
    align-items: flex-start;
}
.vStack-center{
    align-items: center;
}
.vStack-right{
    align-items: flex-end;
}

.vStack-top{
    justify-content: flex-start;
}

.hStack-left{
    justify-content: flex-start;
}
.hStack-center{
    justify-content: center;
}
.hStack-right{
    justify-content: flex-end;
}

.hStack-stretch{
    align-items: stretch;
}


.spacer{
    opacity: 0;
}

.spacer-xs{
    height: 15px;
    width: 15px;
}

.spacer-s{
    height: 25px;
    width: 25px;
}

.spacer-m{
    height: 50px;
    width: 50px;
}

.spacer-l{
    height: 100px;
}

.spacer-xl{
    height: 200px;
}


.separator{
    width: 100%;
    height: 1px;
    background-color: hsl(0, 0%, 90%);
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 10%);
    }
}

.bg-1{
    background: linear-gradient(60deg, hsl(240,100%,25%) 0%, hsl(200,100%,50%) 50%, hsl(180,100%,75%) 100%)
}



.wrapper {
    position: relative; /* Contains the absolute positioning */
  }
  
  .video,
  .canvas {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 30px;
  }
  
  .canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Place the canvas in a lower stacking level */
    width: 100%;
    height: 100%;
    opacity: 1;
    filter: blur(100px);
  }
  
  .filter-blur {
    filter: url(#ambilight);
    overflow: visible;
  }